import React, { Component } from 'react';
import '../MTLTaxConsent.css';

export default class Rule extends Component<any, any> {
  async componentDidMount() {
    // const urlParams = new URLSearchParams(window.location.search);
    // const key = urlParams.get('key');
    const agree = window.sessionStorage.getItem('consent_agree');
    if (agree) this.setState({ agree: JSON.parse(agree) });
  }

  constructor(props: any) {
    super(props);
    this.state = {
      inputType: 'sms',
      telephone: '',
      email: '',
      telephone_isValid: true,
      email_isValid: true,
      submitEnable: false,
    };
  }

  handleSubmit = async () => {
    this.props.history.push('/taxConsent/check');
  };

  render() {
    return (
      <div className="wrap-all">
        <div className="wrap-logo">
          <div className="logo-mtl">
            <img src="/images/MTL-logo.png" alt="logo" />
          </div>
          <div className="logo-smile">
            <img src="/images/Graphic.svg" alt="smileLogo" />
          </div>
        </div>
        <div className="wrap-content" style={{}}>
          <h2 className="header">
            แบบฟอร์มแจ้งความประสงค์ในการใช้สิทธิ
            <br />
            ขอยกเว้นภาษีเงินได้ตามกฎหมายว่าด้วยภาษีอากร
          </h2>
          <div className="box-consent">
            <div onClick={this.handleSubmit} className="select-btn">
              ดำเนินการต่อ
            </div>
          </div>
          <div className="box-consent">
            <div className="rule">
              &emsp;หากท่านต้องการใช้สิทธิขอยกเว้นภาษีเงินได้ตามกฎหมายว่าด้วยภาษีอากร
              ท่านต้องแจ้งความประสงค์และยินยอมให้บริษัทฯ
              เปิดเผยข้อมูลเกี่ยวกับเบี้ยประกันภัยต่อกรมสรรพากร ตามหลักเกณฑ์
              วิธีการที่กรมสรรพากรกำหนด
              <br />
              &emsp;และตั้งแต่วันที่ 1 มกราคม 2564 เป็นต้นมา บริษัทฯ
              ขอยกเลิกการจัดส่งหนังสือรับรองการชำระเบี้ยประกันภัยในรูปแบบกระดาษ
              โดยท่านสามารถดาวน์โหลดหนังสือรับรองการชำระเบี้ยประกันภัยในรูปแบบ
              PDF
              และสามารถตรวจสอบเบี้ยประกันภัยสำหรับอ้างอิงใช้สิทธิยกเว้นภาษีเงินได้
              ในเว็บไซต์นี้
              <br />
              &emsp;ทั้งนี้ การเปลี่ยนแปลงรายละเอียดสัญญาประกันภัย
              การยกเลิกสัญญาเพิ่มเติม หรือการเวนคืนกรมธรรม์
              ก่อนเกณฑ์เวลาหรือเงื่อนไขที่กรมสรรพากรกำหนดไว้
              อาจทำให้มีผลกระทบกับการใช้สิทธิลดหย่อนภาษีเงินได้บุคคลธรรมดา
            </div>
          </div>
        </div>
      </div>
    );
  }
}
